.inner {
    display: flex;
    justify-content: space-between;

    display: flex;
    justify-content: center;
    align-items: center;

    @media (min-width: 75em) {
      padding-top: calc(var(--mantine-spacing-xl) * 4);
      padding-bottom: calc(var(--mantine-spacing-xl) * 4);
    }

    @media (max-width: 75em) {
      padding-top: calc(var(--mantine-spacing-xl) * 1);
      padding-bottom: calc(var(--mantine-spacing-xl) * 1);
    }
  }
  
  .content {
    /* border: 2px solid yellow; */
    max-width: rem(480px);
    margin-right: calc(var(--mantine-spacing-xl) * 3);
  
    @media (max-width: 62em) {
      max-width: 100%;
      margin-right: 0;
    }
  }
  
  .title {
    color: light-dark(var(--mantine-color-black), var(--mantine-color-white));
    font-family:
      Greycliff CF,
      var(--mantine-font-family);
    font-size: rem(44px);
    line-height: 1.2;
    font-weight: 900;
  
    @media (max-width: 36em) {
      font-size: rem(28px);
    }
  }
  
  .control {
    color: var(--mantine-color-black);
    background-color: var(--mantine-color-white);
    @media (max-width: 36em) {
      flex: 1;
    }
  }

  .carouselConainer {
    /* border: 2px solid yellow; */
    @media (max-width: 75em) {
      padding-top: calc(var(--mantine-spacing-xl) * 2);
      width: 95vw;
    }
  }
  
  .image {
    width: 250px;
    /* height: 356px; */
  

  }

  
  .highlight {
    position: relative;
    background-color: #d2a3b5;
    color: var(--mantine-color-black);
    border-radius: var(--mantine-radius-sm);
    padding: rem(4px) rem(12px);
  }