.card {
    height: 600px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    background-size: cover;
    background-position: center;

    @media (max-width: 62em) {
     width: 90vw;
     height: 95vh;
    }
  }
  
  .indicator {
    width: 12px;
    height: 4px;
    transition: width 250ms ease;
  
    &[data-active] {
      width: 40px;
    }
  }